import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import SystemStats from './SystemStats'

// import AppError from './AppError'

export default (history) => combineReducers({
    router: connectRouter(history),

    // Application errors
    // appError: AppError,

    systemStats : SystemStats
})
