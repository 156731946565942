
export const signInConfig = {
  hiddenDefaults: ['phone_number', 'username', 'email', 'password'],
  signInFields: [
    {
      label: 'Email',
      key: 'username', // !!!
      required: true,
      displayOrder: 1,
      type: 'email',
      custom: false
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      custom: false
    },
    {
      label: 'Phone Number',
      key: 'phone_number',
      required: true,
      displayOrder: 3,
      type: 'tel',
      custom: false
    }
  ]
};

export const signUpConfig = {
    // usernameOrSignInOpts: 'email',
  hiddenDefaults: ['phone_number', 'username', 'password', 'email'],
  signUpFields: [
    {
      label: 'Email',
      key: 'username', // !!!
      required: true,
      displayOrder: 1,
      type: 'email',
      custom: false
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 9,
      type: 'password',
      custom: false
    },
    // {
    //   label: 'Phone Number',
    //   key: 'phone_number',
    //   required: true,
    //   displayOrder: 3,
    //   type: 'tel',
    //   custom: false
    // }
  ]
};
