import React from 'react'
import Chart from 'chart.js'

const weekendColors = {
    beforeDraw: (chart, options) => {

        const { data: { weekends }, chartArea, scales: { "x-axis-0": xAxis } } = chart
        const { left, right, top, bottom } = chartArea

        if (!weekends) { return }

        const { data } = chart.getDatasetMeta(0)
        const unitX = data[1]._view.x - data[0]._view.x;

        const ctx = chart.ctx;
        ctx.save();
        // ctx.strokeStyle = 'red';
        // ctx.lineWidth = 1;

        ctx.fillStyle = 'rgba(100,100,100, .05)';

        weekends.map( (weekend, index) => {
            if (!weekend) return
            ctx.fillRect(data[index]._view.x - unitX * 0.5, top, unitX, bottom-top);
        })

        ctx.restore()
    }
}

class LineChart extends React.PureComponent {
    chartRef = React.createRef();
    chartInstance = undefined;

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.buildChart(this.props.data);

        const { visibility } = this.props
        const chart = this.chartInstance

        visibility.map((visible, index) => {
            chart.getDatasetMeta(index).hidden = visible
        })

        // chart.data.datasets[0].data[2] = 20; // Would update the first dataset's value of 'March' to be 50

        chart.update({
          duration: 500,
          easing: 'easeOutQuart'
        })
        // forceUpdate({}); // re-draw component to update legend styles
    }

    render() {
        return ( <canvas ref={this.chartRef} /> ) //id="myChart"
    }

    buildChart(data) {
        const { type="line", height, options, average, labels } = this.props;
        const context = this.chartRef.current.getContext("2d");
        const { width: graphWidth, height: graphHeight } = context.canvas;

        const gradientStroke = context.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "#80b6f4");
        gradientStroke.addColorStop(1, "#f49080");

        const gradientLine = context
        .createLinearGradient(graphWidth, 0, graphHeight, 0);
        // .createLinearGradient(0, 0, graphWidth * 2, 0);
        gradientLine.addColorStop(0, "#FF006E");
        // gradientLine.addColorStop(0, "#0000FF");
        gradientLine.addColorStop(1, "#F46036");
        // gradientLine.addColorStop(1, "#FF0000");

        const gradientFill = context.createLinearGradient(500, 0, 100, 0);
        gradientFill.addColorStop(0, "rgba(128, 182, 244, 0.6)");
        gradientFill.addColorStop(1, "rgba(244, 144, 128, 0.6)");

        const gradientVFill = context
        .createLinearGradient(0, 0, 0, graphHeight);
        gradientVFill.addColorStop(0, "rgb(255, 0, 110, 0.2)");
        gradientVFill.addColorStop(0.5, "rgb(255, 0, 110, 0.35)");
        gradientVFill.addColorStop(1, "rgb(255, 0, 110, 0.7)");


        if (typeof this.chartInstance !== "undefined") {
            this.chartInstance.destroy();
        }

        const otherData = {
            labels: labels,
            datasets: [
                {
                    label: "Sales",
                    data: data,
                    // fill: false,
                    backgroundColor: gradientVFill,
                    // borderColor: "#6610f2"
                    borderColor:               gradientLine,
                    pointBorderColor:          gradientLine,
                    pointBackgroundColor:      gradientLine,
                    pointHoverBackgroundColor: gradientLine,
                    pointHoverBorderColor:     gradientLine
                },
                {
                    label: "National Average",
                    data: average,
                    // fill: false,
                    backgroundColor: gradientVFill,
                    // borderColor: "#E0E0E0"
                }
            ]
        }

        this.chartInstance = new Chart(context, {
            plugins: [weekendColors],
            type,
            height,
            data,
            options
        })
    }
}

export default LineChart



// class LineChartComponent extends React.Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       // legend: "<>no legend</>"
//     };
//   }
//
//   componentDidMount() {
//     const legend = this.generateLegend();
//     this.setState({ legend });
//   }
//
//   generateLegend() {
//     if (!this.myRef) return null;
//     return this.myRef.chartInstance.generateLegend();
//   }
//
//   onClick(index) {
//     const { chartInstance: chart } = this.refs.chart;
//     chart.getDatasetMeta(index).hidden =
//     chart.getDatasetMeta(index).hidden === null ? true : !chart.getDatasetMeta(index).hidden;
//     chart.data.datasets[0].data[2] = 20; // Would update the first dataset's value of 'March' to be 50
//
//     chart.update({
//       duration: 500,
//       easing: 'easeOutQuart'
//     }); // re-draw chart to hide dataset
//     // forceUpdate({}); // re-draw component to update legend styles
//   }
//
//   render() {
//     const { options } = this.props
//     const { chartInstance = {} } = this.refs.chart || {};
//     const { legend = {} } = chartInstance;
//     const { legendItems = [] } = legend;
//
//     return (
//       <div>
//         <Legend items={legendItems} chart={chartInstance} onClick={index => { this.onClick(index) }} />
//         <Line data={systemStats} {...this.props} ref="chart" />
//       </div>
//     );
//   }
// }
