import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components'

// import { getSelectedApp } from 'selectors'
import { systemStats, getHydroDate } from 'selectors/SystemStats'
import { search, changeDate } from 'actions/SystemStats'

import SystemInfoHeader from './SystemInfoHeader'
import DevAntChart from './DevAntChart'

import { options as AppOverviewOptions } from './AppOverviewConfig'
import { options as AppLaunchesOptions } from './AppLaunchesConfig'


import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates'


const axis = ({id, label, borderColor, backgroundColor, borderWidth=2, fill=true, tension=0}) => ({
    yAxisID: id,
    label,
    fill,
    lineTension: tension,
    backgroundColor,

    borderColor,
    borderWidth,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',

    pointRadius: 0,
    pointHitRadius: 10,
    pointBorderColor: borderColor,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 4,
    pointHoverBackgroundColor: 'white',
    pointHoverBorderColor: borderColor,
    pointHoverBorderWidth: 2
})

const Container = styled.div`
    height: 100%;
    overflow-y: scroll;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px;
`

const GridItem = styled.div`
    padding: 10px;
    background: white;
    box-shadow: 0 1px 3px 0 #cfd8dc;
    ${ props => props.wide && `
        grid-column: 1 / span 2;
    `}
`

class ApplicationDashboard extends React.Component {

    async componentDidMount() {

        //, match: { params: { appId, companyId } }
        const {  search, hydroDate } = this.props

        if (!hydroDate) {
            return null
        }

        // if (issues.length) return
        // search({ deviceId: "0816dbc2-57ad-46a0-990c-2d7de2213f53", startDate: moment().subtract(1, 'days') })
        search({ deviceId: "0816dbc2-57ad-46a0-990c-2d7de2213f53", startDate: hydroDate })
    }

    render() {
        const { app, systemStats, hydroDate } = this.props


        // const startDate = round(moment(), moment.duration(15, "minutes"), "ceil");
        // console.log(startDate.format("YYYYMMDDHHmm"));

        // set seconds to 0 so 10:50:01 doesn't round up to 10:55:00

        if (!hydroDate) {
            return null
        }



        const startDate = hydroDate.set('seconds', 0).subtract(0, "days")
        const interval = 5

        const dateCount = 24 * 60 / 5 // 24 hours, one record every 5 mintes (350 is a bit more than a day)
        const defaultStat = {
            pressure: 0,
            tankTemp: 0,
            humidity: 0,
            tube1Temp: 0,
            tube2Temp: 0,
            tankEC: 0,
            tankPH: 0,
            temperature: 0
        }

        function round(date, duration, method) {
            return moment(Math[method]((+date) / (+duration)) * (+duration));
        }



        const dates = [...Array(dateCount)].reduce(( result, item, index) => {
            // 5 minute intervals, round to next 5 min
            const d = round(moment(startDate).subtract(index * interval, "minutes"), moment.duration(interval, "minutes"), "ceil")
            return [ d, ...result ]
        }, [])

        const weekends = dates.reduce(( result, date ) => {
            const weekday = date.format('dddd'); // Monday ... Sunday
            const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
            return [ ...result, isWeekend ]
        }, [])

        const labels = dates.reduce(( result, date ) => {

            return [ ...result, date.format("MMM DD HH:mm ss") ]
            // return [ ...result, date.format("MMM Do") ]
        }, [])

        const dateIndex = date => date.format("YYYYMMDDHHmm")

        // Creates an object containing an array of values for each metric
        // {
        //     labels: ['Feb 12', 'Feb 13', 'Feb 14'],
        //     downloads: [1,2,3,4,5,6],
        //     visits: [1,2,3,4,5,6],
        //     ...
        // }
        const seriesData = dates.reduce(( result, date ) => {
            const stat = systemStats[dateIndex(date)] || { }

            // use defaultStat for keys as keys in stat object might be missing
            return Object.keys(defaultStat).reduce((result, key) => {
                if (defaultStat.hasOwnProperty(key)) {
                    const value = stat[key] || 0
                    return { ...result, [key]: [ ...result[key] || [], value ] }
                }
            }, result)
        }, { labels, weekends })

        // console.log('seriesData', seriesData);

        // OVERVIEW
        const airTempAxis    = axis({ id:'A', label:'Air Temp',     borderColor: '#6eafd4',   backgroundColor: '#6eafd444' })
        const humidityAxis   = axis({ id:'B', label:'Humidity',     borderColor: 'rgb(170, 147, 196)',  backgroundColor: 'rgba(218, 234, 243, 0.6)', backgroundColor: 'rgba(170, 147, 196, 0.2)' })
        const tankTempAxis   = axis({ id:'A', label:'Tank Temp',    borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })

        const metricsOverview = [
            { axis: humidityAxis,   property: 'humidity',   title: 'Humidity',   value: 222,         color: 'rgb(170, 147, 196)'},
            { axis: airTempAxis,    property: 'temperature',title: 'Air Temp',value: 345,         color: '#6eafd4'},
            { axis: tankTempAxis,   property: 'tankTemp',   title: 'Tank Temp',  value: 60.56,    color: '#77c379' }
        ]

        // TDS
        const tankTempTDSAxis   = axis({ id:'B', label:'Tank Temp',    borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })
        const airTempTDSAxis    = axis({ id:'B', label:'Air Temp',     borderColor: '#6eafd4',   backgroundColor: '#6eafd444' })
        const ecTDSAxis         = axis({ id:'A', label:'EC',    borderColor: 'rgb(70, 147, 196)',   backgroundColor: 'rgba(218, 234, 243, 0.6)' })
        // const downloadsSalesAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgba(75,192,192,1)',  backgroundColor: 'rgba(75,192,192,0.4)' })
        // const installsSalesAxis   = axis({ id:'B', label:'Revenue',   borderColor: 'rgba(75,192,192,1)',  backgroundColor: 'rgba(75,192,192,0.4)' })
        //
        const metricsTDS = [
            { axis: tankTempTDSAxis,    property: 'tankTemp',    title: 'Tank Temp',    value: 23,          color: '#77c379' },
            { axis: airTempTDSAxis,     property: 'temperature',title: 'Air Temp',value: 345,         color: '#6eafd4'},
            { axis: ecTDSAxis,          property: 'tankEC',    title: 'EC',    value: 23,          color: '#4693c4' },
            // { axis: downloadsSalesAxis,  property: 'downloadsSales', title: 'Downloads > Sales', value: '$60.56',    color: '#77c379',   difference: 10 },
            // { axis: installsSalesAxis,   property: 'installsSales',  title: 'Installs > Sales',  value: 56,          color: '#6eafd4',   difference: 0 }
        ]

        // PRESSURE
        const pressureAxis   = axis({ id:'A', label:'Pressure',    borderColor: 'rgb(70, 147, 196)',   backgroundColor: '#6eafd4', borderWidth: 0 })
        // const firstTimeAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgba(75,192,192,1)',  backgroundColor: '#4693c4', borderWidth: 0 })
        //
        const metricsPressure = [
            { axis: pressureAxis,   property: 'pressure',    title: 'Pressure',        value: 56,   color: '#6eafd4' },
        //     { axis: launchesAxis,    property: 'launches',             title: 'Launches',          value: 23,   color: '#4693c4',   difference: 10 }
        ]

        // PH
        const phAxis   = axis({ id:'A', label:'PH',    borderColor: 'rgb(70, 147, 196)',   backgroundColor: '#6eafd4', borderWidth: 0 })
        // const firstTimeAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgba(75,192,192,1)',  backgroundColor: '#4693c4', borderWidth: 0 })
        //
        const metricsPH = [
            { axis: phAxis,   property: 'tankPH',    title: 'PH',        value: 56,   color: '#6eafd4' },
        //     { axis: launchesAxis,    property: 'launches',             title: 'Launches',          value: 23,   color: '#4693c4',   difference: 10 }
        ]

        const tankTempTubesAxis = axis({ id:'A', label:'Tank Temp',    borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })
        const tube1TempAxis     = axis({ id:'A', label:'Tube 1 Temp',    borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })
        const tube2TempAxis     = axis({ id:'A', label:'Tube 2 Temp',    borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })

        // TUBES
        const metricsTubes = [
            { axis: tankTempTubesAxis,    property: 'tankTemp',    title: 'Tank Temp',    value: 23,          color: '#77c379' },
            { axis: tube1TempAxis,     property: 'tube1Temp',      title: 'Tube 1 Temp', value: 23,    color: '#4693c4' },
            { axis: tube2TempAxis,   property: 'tube2Temp',          title: 'Tube 2 Temp',    value: 56,    color: '#6eafd4' }
        ]
        //
        // // FEEDBACK
        // const metricsFeedback = [
        //     { axis: ordersAxis,     property: 'feedbackAttention',    title: 'Require Attention', value: 23,    color: '#4693c4',   difference: 10 },
        //     { axis: downloadAxis,   property: 'feedbackTotal',        title: 'Total Received',    value: 56,    color: '#6eafd4',   difference: -34 }
        // ]

        const title = `Strawberry`

        const headerInfo = (
            <SystemInfoHeader app={app} title={`Strawberry ${startDate.format("DD MMM YYYY, HH:mm ")}`}></SystemInfoHeader>
        )

        return (
            <Container>

                <Grid>

                    <GridItem wide>
                        <DevAntChart {...this.props} options={AppOverviewOptions} headerInfo={headerInfo} height="240" seriesData={seriesData} metrics={metricsOverview} firstDate={dates[0]} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppOverviewOptions} title="Disolved solids" seriesData={seriesData} metrics={metricsTDS} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="PH" seriesData={seriesData} metrics={metricsPH} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Barometric Pressure" seriesData={seriesData} metrics={metricsPressure} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Tube Temps" seriesData={seriesData} metrics={metricsTubes} />
                    </GridItem>
{/*
                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Feedback" type="bar" seriesData={seriesData} metrics={metricsFeedback} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Users by App Version" type="pie" seriesData={seriesData} metrics={metricsIssues} />
                    </GridItem>*/}
                </Grid>
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // app: getSelectedApp(state, props),
        hydroDate: getHydroDate(state, props),
        systemStats: systemStats(state, props)
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (params) => dispatch(search(params))
    // licensesSearch: (companyId, appId, search) => dispatch(licensesSearch(companyId, appId, search))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDashboard)
