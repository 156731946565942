
// export const systemStats = (state) => state.systemStats.stats
// export const systemStats = (state) => state.systemStats.list.items
export const systemStats = (state, props) => state.systemStats.statsByDeviceId["0816dbc2-57ad-46a0-990c-2d7de2213f53"] || {}
// export const systemStats = (state, props) => state.systemStats.statsByAppId[props.match.params.appId] || {}
export const loadingSystemStats = (state) => state.systemStats.isLoading

export const totals = (state, props) => state.systemStats.totalsByAppId

export const getHydroDate = (state, props) => state.systemStats.hydroDate
