export const options = {
    maintainAspectRatio: false,
    legend: false,
    // legendCallback : function(chart) {
    //     // console.log('chart', chart.data);
    //     return (
    //         <h1>a</h1>
    //     )
    // }


    fillBackground: (chart, index, firstDate) => {
        if (!firstDate) return false

        const date = firstDate.add(index, "days")
        const weekday = date.format('dddd'); // Monday ... Sunday
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';

        return isWeekend
    },

    //Customize chart options
    tooltips: {
        displayColors: false,
        titleFontSize: 16,
        bodyFontSize: 14,
        xPadding: 10,
        yPadding: 10,
        titleMarginBottom: 10,
        // titleSpacing: 20,
        callbacks: {
            label: (tooltipItem, data) => {
                const { index } = tooltipItem
                const { datasets } = data

                console.log(tooltipItem, data);

                return datasets.reduce((result, dataset) => {
                    const { label, data } = dataset
                    return [ ...result, `${label}: ${data[index]}` ]
                }, [])

                //
                // return [`Revenue: $${tooltipItem.value}`, `Sales: 345`, `Downloads: 111`]
            }
        }
    },

    scales: {
        xAxes: [{
            gridLines: {
                color: "#eaeaea"
            },
            ticks: {
                autoSkip: true,
                fontSize: 10,
                fontColor: "#999",
                maxRotation: 0 // turns off rotation
            },
            afterTickToLabelConversion: ({ ticks }) => {
                ticks.forEach((tick, i) => {
                    if (i % 2 == 0) {
                        ticks[i] = ''
                    }
                })
            }

        }],
        yAxes: [{
            id: 'A',
            type: 'linear',
            display: true,
            position: 'left',
            gridLines: {
                borderDash: [4,4],
                color: "#eaeaea"
            },
            ticks: {
                mirror: true,
                padding: -3,
                fontSize: 9,
                maxTicksLimit: 5,
                beginAtZero: true
            }
        }, {
            id: 'B',
            type: 'linear',
            display: true,
            position: 'right',
            gridLines: {
                display: false
            },
            ticks: {
                mirror: true,
                padding: -3,
                fontSize: 9,
                maxTicksLimit: 5,
                beginAtZero: true,
                // Include a dollar sign in the ticks
                // callback: function(value, index, values) {
                //     return '$' + value;
                // }
            }
        }]
    }
}
