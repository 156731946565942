import { Actions } from 'Constants'
import moment from 'moment';

const defaultState = {
    isLoading: true,
    hydroDate: moment(),

    statsByDeviceId: { },

    statsByAppId: { },

    totalsByAppId: {
        // 400... : {revenue:234, downloads:2}
    }
}

function round(date, duration, method) {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}

const SystemStats = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.UI_HYDRO_DATE:
            return {
                ...state,
                hydroDate: action.date
            }
        case Actions.UI_STATISTICS_LIST:

            // console.log(action);


            return {
                ...state,
                // LastEvaluatedKey: action.LastEvaluatedKey,
                isLoading: false,
                // moreDataAvailable: action.LastEvaluatedKey !== undefined,
                statsByDeviceId: action.items.reduce((result, item) => {
                    const [userId, deviceId] = item.PK.split('|')
                    // const [kind, date] = item.SK.split('|')
                    // moment(item.SK).add(1, 'hour')
                    var date = moment(item.SK, "YYYYMMDDHHmmss").add(1, 'hour')
                    date.set({ second:0, millisecond:0 })



                    date = round(date, moment.duration(5, "minutes"), "ceil");
                    date = date.format("YYYYMMDDHHmm")
                    // const date = item.SK.substr(0,12)

                    return { ...result, [deviceId]: { ...result[deviceId], [date]: item  } }
                    // return { ...result, [appId]: { ...result[appId], [date]: item  } }
                }, { ...state.statsByDeviceId })
            }



            // App specific statistics
            if (action.appId) {
                return {
                    ...state,
                    // LastEvaluatedKey: action.LastEvaluatedKey,
                    isLoading: false,
                    // moreDataAvailable: action.LastEvaluatedKey !== undefined,
                    statsByAppId: action.items.reduce((result, item) => {
                        const [companyId, appId] = item.PK.split('|')
                        const [kind, date] = item.SK.split('|')

                        return { ...result, [appId]: { ...result[appId], [date]: item  } }
                    }, { ...state.statsByAppId })
                }
            }

            // App totals
            else {

                return {
                    ...state,

                    totalsByAppId: action.items.reduce((result, item) => {
                        const { PK, SK, ...rest } = item

                        return Object.keys(rest).reduce((result, key) => {
                            const [appId, statistic] = key.split('|')
                            const appIdStats = result[appId] || {}
                            const value = appIdStats[statistic] || 0
                            const newValue = value + rest[key]

                            return {
                                ...result,
                                [appId] : { ...result[appId], [statistic]:newValue }
                            }
                        }, result)

                    }, {})
                }
            }

        default:
        return state
    }
}

export default SystemStats
