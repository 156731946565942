import React, { useState } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components'
import moment from 'moment';

// import { getSelectedApp } from 'selectors'
import { loadingSystemStats } from 'selectors/SystemStats'

import LineChart from './LineChart'
import Legend, { LegendItem } from './Legend'
import Spinner from 'Spinner'

const Container = styled.div`
    ${ props => props.loading && `
        filter: grayscale(90%) opacity(0.5);
    `}
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em;
`

const Title = styled.div`
    color: #1964c9;
    line-height: 30px;
    font-size: 1.2em;
    font-weight: 400;
    display: inline-block;
`

const FlexGrow = styled.div`
    flex-grow: 1;
`

const ChartContainer = styled.div`
    height: ${ props => props.height }px;
`

class DevAntChart extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {}
    }

    toggleDataset(dataset) {
        this.setState({
            [dataset]: !this.state[dataset]
        })
    }

    render() {
        // UI State
        const { downloads, customers, revenue } = this.state

        // Data
        const { isLoading, app, seriesData, metrics=[], firstDate } = this.props

        // Chart config
        const { type, showAppInfo, headerInfo, title, height=150, options={} } = this.props

        // Build chart series visibility
        const visibility = metrics.reduce((result, metric) => {
            return [...result, this.state[metric.property]]
        }, [])

        const datasets = metrics.reduce((result, metric) => {
            return [...result, { ...metric.axis, data:seriesData[metric.property] }]
        }, [])


        return (
            <Container loading={isLoading} >
                { isLoading &&
                    <Spinner />
                }

                { title &&
                    <Title>
                        {title}
                    </Title>
                }

                <Header>
                    { headerInfo &&
                        <FlexGrow>
                            {headerInfo}
                        </FlexGrow>
                    }

                    <FlexGrow>
                        <Legend>
                            { metrics.map((metric) => {
                                // const value = Math.round([...seriesData[metric.property]].sort().reduce((a, b) => a + b, 0))
                                const value = [...seriesData[metric.property]].reduce((a, b) => b, 0)
                                const roundedValue = Math.round(value * 100 + Number.EPSILON ) / 100
                                return (
                                    <LegendItem text={metric.title} valuePrefix={metric.valuePrefix} value={roundedValue} difference={metric.difference} color={metric.color} onClick={() => this.toggleDataset(metric.property)} />
                                )
                            })}
                        </Legend>
                    </FlexGrow>
                </Header>

                <ChartContainer height={height}>
                    <LineChart
                        type={type}
                        visibility={visibility}
                        data={{
                            labels: seriesData['labels'],
                            weekends: seriesData['weekends'],
                            datasets
                        }}
                        options={options}
                    />
                </ChartContainer>
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // app: getSelectedApp(state, props),
        isLoading: loadingSystemStats(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DevAntChart)




const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 5,
            left: 15,
            right: 15,
            bottom: 15
        }
    },
    scales: {
        xAxes: [{
            ticks: { display: true },
            gridLines: {
                display: true,
                drawBorder: true
            }
        }],
        yAxes: [{
            ticks: { display: false },
            gridLines: {
                display: false,
                drawBorder: false
            }
        }]
    }
}
