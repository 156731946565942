import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components'

import { getHydroDate } from 'selectors/SystemStats'
import { search, changeDate } from 'actions/SystemStats'

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates'

const Container = styled.div`
    margin: 20px;
`

class Toolbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    dateSelected(date) {
        const { search, changeDate } = this.props
        const now = moment()

        // Chosen date doesn't have time, add it
        date.set('second', now.second())
        date.set('minute', now.minute())
        date.set('hour', now.hour())

        changeDate({date})
        search({ deviceId: "0816dbc2-57ad-46a0-990c-2d7de2213f53", startDate: date })
    }

    render() {
        const { changeDate, hydroDate } = this.props
        const { date, focused } = this.state

        const minDate = moment("20200419","YYYYMMDD")

        return (
            <Container>
                <SingleDatePicker
                    isOutsideRange={date => date.isBefore(minDate) || date.isAfter(moment().add(1, "days"))}
                    numberOfMonths={1}
                    date={hydroDate}
                    onDateChange={date => this.dateSelected(date)}
                    focused={focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id="your_unique_id"
                />
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        hydroDate: getHydroDate(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    changeDate: (date) => dispatch(changeDate(date)),
    search: (params) => dispatch(search(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toolbar)
