// import { createAction } from 'redux-actions';
import { push } from 'connected-react-router'
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { Actions } from 'Constants'
import moment from 'moment';

// export const getSearch = state => state.licenses.list.search

export const changeDate = ({date}) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_HYDRO_DATE, date })
    }
}

export const search = ({deviceId, startDate = moment().subtract(1, 'days'), LastEvaluatedKey}) => {
    console.log("search called");
    return async (dispatch, getState) => {
        // dispatch({ type: Actions.UI_LICENSES_LIST, search })

        console.log('getting stats', deviceId, moment(startDate).subtract(1, 'days').format('YYYYMMDDHHmmss'), LastEvaluatedKey);



        try {
            let req = {
                headers: {},
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    deviceId,
                    // ...(appId && {appId}),
                    startDate: moment(startDate).subtract(1, 'days').format('YYYYMMDDHHmmss'),
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            console.log('req', req);

            API.post("v1API", "/stats", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Might need to check if date range has changed
                // Check search params haven't changed
                // const currentSearch = getSearch(getState())
                // if (currentSearch !== search) return

                dispatch({
                    type: Actions.UI_STATISTICS_LIST,
                    // listAction: ListActions.ITEMS,
                    items,
                    LastEvaluatedKey,
                    // ...(deviceId && {deviceId})
                })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

// export const licensesPage = (companyId, appId) => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const searchValue = state.licenses.list.search
//         const LastEvaluatedKey = state.licenses.list.LastEvaluatedKey
//
//         dispatch(search(companyId, appId, searchValue, LastEvaluatedKey))
//     }
// }
