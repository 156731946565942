import Amplify, { API, PubSub, Auth } from 'aws-amplify';
import { Actions } from 'Constants'
import moment from 'moment';

export const mqttPost = (companyName="Unknown Company") => {
    // PubSub.publish('room/public/tom/us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf13', { msg: 'Hello to all subscribers!' })
     // PubSub.publish('room/public/tom/us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { msg: 'Hello to all subscribers!' })
     PubSub.publish('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { msg: 'Hello to all subscribers!', value:1, companyName })
}

const onNewMessageWithRedux = (dispatch, getState) => ((provider) => {
    const { value } = provider
    const { userId, deviceId } = value

    const type = Actions.UI_STATISTICS_LIST
    const items = [{ PK:`${userId}|${deviceId}`, SK: `minute|${moment().subtract(1, 'hour').format("YYYYMMDDHHmmss")}`, ...value }]

    dispatch({type, items})


    console.log('Message received', value)
});

export const performIOTConnect = () => {
    return async (dispatch, getState) => {

        console.log('Running');
        const user = await Auth.currentUserInfo()


        let req = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {}
        }

        API.post("v1API", "/iot/policy", req).then(response => {

            console.log('Policy done');



            // PubSub.subscribe(`${user.id}`).subscribe({
            //     next: onNewMessageWithRedux(dispatch, getState),
            //     error: error => { console.log('errored'); console.error(error) },
            //     close: () => console.log('Done'),
            // });
            PubSub.subscribe(`hydro/nft/status`).subscribe({
                next: onNewMessageWithRedux(dispatch, getState),
                error: error => { console.log('errored'); console.error(error) },
                close: () => console.log('Done'),
            });

            console.log('Subscribed');

            // PubSub.publish('hydro/nft/test', 'testing testing...')

            // PubSub.subscribe('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18').subscribe({
            //     next: onNewMessageWithRedux(dispatch, getState),
            //     error: error => console.error(error),
            //     close: () => console.log('Done'),
            // });
        }).catch(error => {
            console.log(error)
        })
    }
}
