
export const options = {
    maintainAspectRatio: false,
    legend: false,
    scales: {
        xAxes: [{
            stacked: true,
            gridLines: {
                // display: false
            },
            ticks: {
                // autoSkip: true,
                fontSize: 10,
                fontColor: "#999",
                maxRotation: 0 // turns off rotation
            },
            afterTickToLabelConversion: ({ ticks }) => {
                const s = ticks.length / 3

                ticks.forEach((tick, i) => {
                    if (i % s !== 0 && i !== ticks.length-1) {
                        ticks[i] = ''
                    }
                })
            }

        }],
        yAxes: [{
            id: 'A',
            // type: 'linear',
            // display: false,
            // position: 'left',
            // stacked: true,
            gridLines: {
                display: false
            },
            ticks: {
                mirror: true,
                display: false
                //     fontSize: 9,
                //     maxTicksLimit: 5,
                //     beginAtZero: true
            }
        }]
    }
}
