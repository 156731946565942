import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components'

import { systemStats } from 'selectors/SystemStats'
import { search } from 'actions/SystemStats'

const Container = styled.div`

`


const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: auto;

`

const StyledList = styled.div`
    background: white;
    max-width: 25em;
`

const StyledListHeader = styled.div`
    height: 2em;
    display: flex;
    align-items: center;
    font-size: .9em;
    color: #9a9a9a;
    border-bottom: 1px solid #ececec !important;
`

const StyledHeaderRow = styled.div`
    display: flex !important;
    align-items: center;
    padding: 0.5em 1em;
    border-bottom: 1px solid #ececec !important;
    position: sticky !important;
    top: 0  !important;
    background: white;
    z-index: 1;
`

const StyledRow = styled.div`
    display: flex !important;
    align-items: center;
    border: 0 !important;
    padding: .8em 1em !important;
    border-bottom: 1px solid #ececec !important;
`

const StyledListHeaderItem = styled.div`
    font-size: .9em;
    color: #9a9a9a;
    align-self: center;
    position: sticky;
    top: 0;
    background white;
`

const PrimaryColumn = styled.div`
    flex: 1;
`

const DetailColumn = styled.div`
    width: 10em;
    text-align: right;
`

const ColumnText = styled.div`
    flex: 1fr;
    font-weight: 300;
    font-size: 1.5em;
    color: #446f7b;
`

const statisticValue = (totals, appId, statistic) => {
    const stats = totals[appId] || {}
    return Math.round(stats[statistic]) || 0
}



class Toolbar extends React.Component {

    selectApp(app) {
        const { performAppNav, match: { params: { companyId } } } = this.props
        const { appId } = app
        // performAppNav({companyId, appId})
    }


    appItems() {
        const { systemStats } = this.props


        const interval = 5
        const round = (date, duration, method) => moment(Math[method]((+date) / (+duration)) * (+duration))
        const now = round(moment(), moment.duration(interval, "minutes"), "ceil").format("YYYYMMDDHHmm")

        const stat = systemStats[now] || {}
        const dur = moment.duration(stat['millis'], 'millisecond')

        const formatValue = value => Math.round(value * 100 + Number.EPSILON ) / 100

        const stats = [{
            title: 'Air Temperature',
            value: formatValue(stat['temperature'])
        }, {
            title: 'Humidity',
            value: formatValue(stat['humidity'])
        }, {
            title: 'Tank Temperature',
            value: formatValue(stat['tankTemp'])
        }, {
            title: 'PH',
            value: formatValue(stat['tankPH'])
        }, {
            title: 'Disolved solids EC',
            value: formatValue(stat['tankEC'])
        }, {
            title: 'Upper Tube Temperature',
            value: formatValue(stat['tube1Temp'])
        }, {
            title: 'Lower Tube Temperature',
            value: formatValue(stat['tube2Temp'])
        }, {
            title: 'Uptime',
            value: `${dur.hours()}:${dur.minutes()}:${dur.seconds()}`
        }]




        // console.log(now)
        // console.log(systemStats[now])


        // tube1Temp: 16.75
        // deviceId: "0816dbc2-57ad-46a0-990c-2d7de2213f53"
        // pressure: 1004.513
        // tube2Temp: 18.5625

        // <AppInfoHeader app={app} small />

        return stats.map(stat =>
            <StyledRow key={stat.title} onClick={ () => this.selectApp(stat) }>
                <PrimaryColumn>
                    {stat.title}
                </PrimaryColumn>
                <DetailColumn><ColumnText>{stat.value}</ColumnText></DetailColumn>
            </StyledRow>
        )
    }

    render() {
        // <StyledHeaderRow>
        //     <PrimaryColumn><StyledListHeaderItem>Name</StyledListHeaderItem></PrimaryColumn>
        //     <DetailColumn><StyledListHeaderItem></StyledListHeaderItem></DetailColumn>
        // </StyledHeaderRow>

        return (
            <StyledGrid>
                { this.appItems() }
            </StyledGrid>
        )
    }



    render1() {
        const { search } = this.props

        return (
            <Container>
                Overview
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        systemStats: systemStats(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (params) => dispatch(search(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toolbar)
