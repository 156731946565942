/*
 * store.js
 */

import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import logger from 'redux-logger'

import rootReducer from 'reducers'

// const reduxDevTools =
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();


// const store = createStore(rootReducer, applyMiddleware(ReduxThunk))

const configureStore = (options) => {
    const { history } = options
    const preloadedState = {}
    const store = createStore(
        rootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                logger,
                routerMiddleware(history), // for dispatching history actions
                thunk
            )
        )
    )

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('../reducers', () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store
}

export default configureStore;
