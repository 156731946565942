import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history'

import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'

import * as serviceWorker from './serviceWorker';

import configureStore from './state/Store';
import App from './App';

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './index.css';

const history = createBrowserHistory()
const store = configureStore({history})

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Component />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    );
}

render(App)

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        render(NextApp)
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
